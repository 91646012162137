.icons{
    color: #fff;
    background-color: #000;
}

.mainview { 
    background-color: #f2f2f2;
    flex: 1;
}
.card{
  height: 150px;
  width : 250px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow :none !important
}

.icon{
  font-size: 44px;
  color: #0099ff;
}

.cardname {
  margin-top: 7px;
  font-size: 32px;
}
.cardfooter {
  margin-top: 7px;
  font-size: 22px;
}


@media only screen and (max-width: 1250px) {
    .mainview {
      flex: 1;
    }
    .card{
      height: 150px;
      width : 200px;
      background-color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      box-shadow :none !important
    }
    .icon{
      font-size: 34px;
      color: #0099ff;
    }
    .cardname {
      margin-top: 7px;
      font-size: 22px;
    }
    .cardfooter {
      margin-top: 7px;
      font-size: 18px;
    }
  }


@media only screen and (max-width: 950px) {
  .mainview {
    flex: 1;
  }
  .card{
    height: 100px;
    width : 150px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow :none !important
  }
  .icon{
    font-size: 30px;
    color: #0099ff;
  }
  .cardname {
    margin-top: 7px;
    font-size: 16px;
  }
  .cardfooter {
    margin-top: 7px;
    font-size: 15px;
  }
}

  @media only screen and (max-width: 480px) {
    .mainview{
      display: flex;
      flex-direction: column;
    }
  }
  