.summarycard{
    transform: 0.6;
}

.dropbtn {
    background-color: #0099ff;
    color: white;
    padding: 10px;
    font-size: 16px;
    border: none;
    padding-right: 20px;
    padding-left: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
    margin-left: 10px;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 180px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 10px;

  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: flex;
    border-radius: 10px;
    color: #585858;
    justify-content: flex-start;
    align-items: center;
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {background-color: #0099ff;color: #fff;}
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {display: block;    }
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {background-color: #0099ff;}