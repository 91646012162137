* {
  margin: 0;
}
@import url("https://fonts.googleapis.com/css?family=Josefin+Sans");

body {
  font-family: "Josefin Sans", cursive;
}


.imagebackground {
  background-color: #0099ff;
  flex: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imagelogin{
  width: 70%;
  border-radius: 20px;
}

.MainDiv{
  display: flex;
  flex-direction: row;
}


@media only screen and (max-width: 1350px) {
  .imagebackground {
    background-color: #0099ff;
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (max-width: 480px) {
  .MainDiv{
    display: flex;
    flex-direction: column;
  }
  .imagebackground {
    display: none;
  }
}





